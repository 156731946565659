import React from 'react';
import { observer } from 'mobx-react';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { EntitiesStores } from '../types';

import { BackgroundInformationSections } from '../EntityDetailsTab/styles';
import IncidentRelatedToEntities from './components/IncidentRelatedToEntities';
import TasksRelatedToEntities from './components/TasksRelatedToEntities';
// TODO: uncomment this line when backend is ready
// import AuditsRelatedToSites from './components/AuditsRelatedToSites';
type EntityRelatedTabProps = {
  onError: (error: VatixError) => void;
  store: EntitiesStores;
};

const EntityRelatedTab: React.FunctionComponent<EntityRelatedTabProps> = ({ onError, store }) => (
  <BackgroundInformationSections>
    <IncidentRelatedToEntities onError={onError} store={store} />
    <TasksRelatedToEntities onError={onError} store={store} />
    {/* <AuditsRelatedToSites onError={onError} /> */}
  </BackgroundInformationSections>
);

export default observer(EntityRelatedTab);
