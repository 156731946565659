import { makeObservable } from 'mobx';

import DataMixin from 'vatix-ui/lib/utils/stores/DataMixin';

import { EntityModules } from 'core/constants';
import API from 'utils/api';
import RootStore from 'stores/Root';

export default class EntityDetails extends DataMixin<typeof API, RootStore> {
  entityType: EntityModules;

  path: string;

  detailsPath: (uuid: string) => string;

  constructor(
    rootStore: RootStore,
    api: typeof API,
    entityType: EntityModules,
    path: string,
    detailsPath: (uuid: string) => string
  ) {
    super(rootStore, api);
    makeObservable(this);
    this.entityType = entityType;
    this.path = path;
    this.detailsPath = detailsPath;
  }

  reset(): void {
    this.isFailure = false;
    this.isLoaded = false;
  }
}
