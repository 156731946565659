import React from 'react';
import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';
import { Button } from 'vatix-ui/lib/components/Button';

import Record from 'vatix-ui/lib/containers/Record/Record';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { reverse } from 'named-urls';

import { useRouteMatch } from 'react-router-dom';

import { useNavBarHeight } from 'utils/hooks/navbar';
import { HELP_CENTER_URL } from 'core/constants';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import FieldManagerTable from './components/FieldManagerTable/FieldManagerTable';

const FieldManager: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();

  const match = useRouteMatch(routes.dashboard.objectManager.details.fieldManager);

  const { moduleName } = match?.params as { moduleName: string };

  const {
    entityFields: { setActiveField },
  } = useStore();

  const onCreateNew = (): void => {
    setActiveField({
      uuid: '',
      key: '',
      // @ts-expect-error
      protectorType: '',
      name: '',
    });
  };

  const redirectToHelpCenter = (): void => {
    window.open(HELP_CENTER_URL, '_blank');
  };

  return (
    <Record
      key={`field_manager_${moduleName}`}
      Header={
        <MainHeader
          title="Field Manager"
          breadcrumbs={[
            {
              label: 'Object Manager',
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: moduleName.charAt(0).toUpperCase() + moduleName.slice(1),
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: 'Field Manager',
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
          ]}
          action={
            <>
              <Button
                onClick={redirectToHelpCenter}
                variant="text"
                size="large"
                startIcon={<HelpOutlineOutlinedIcon />}
                style={{ marginRight: '4px', width: '210px' }}
              >
                Field Manager Help
              </Button>
              <Button onClick={onCreateNew} variant="contained" size="large" style={{ marginLeft: '4px' }}>
                New
              </Button>
            </>
          }
        />
      }
      Content={(onError) => <FieldManagerTable onError={onError} moduleName={moduleName} />}
      navBarHeight={navBarHeight}
    />
  );
};

export default FieldManager;
