/* eslint-disable max-len */
import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';
import { useRouteMatch } from 'react-router-dom';
import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';
import { NotFound } from 'vatix-ui/lib/components/Error/Pages';

import DataError from 'vatix-ui/lib/components/Error/DataError';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import { isNotFound } from 'utils/api/errors';

import { useNavBarHeight } from 'utils/hooks/navbar';
import { useStore } from 'utils/hooks/store';

import EntityDetailsContent from 'components/Entities/EntityDetailsContent';

import EntityDetailsHeader from 'components/Entities/EntityDetailsHeader';
import EntityDetailsActivities from 'components/Entities/EntityDetailsActivities';
import EntityDetailsActivitiesFooter from 'components/Entities/EntityDetailsActivities/EntityDetailsActivitiesFooter';
import UserRow from 'components/UserRow';

const AssetsDetails: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const match = useRouteMatch<{ assetId: string }>();

  const { assetDetails } = useStore();

  return (
    <DetailsLoader
      match={match}
      routeParam="assetId"
      // @ts-ignore
      store={assetDetails}
    >
      {({ isLoaded, error, reload }): React.ReactElement => {
        if (!isLoaded) {
          return <CenteredCircularProgress />;
        }
        if (assetDetails.details === undefined) {
          return <NotFound />;
        }
        if (error) {
          if (isNotFound(error)) {
            return <NotFound />;
          }
          return <DataError onReload={reload} />;
        }
        const { assetName, assetOwner } = assetDetails.details.entity;
        return (
          <Record
            disableMaxContentHeight
            navBarHeight={navBarHeight}
            Header={
              <EntityDetailsHeader
                store={assetDetails}
                title={assetName.value as string}
                columns={[
                  { name: 'Name', value: <p>{assetName.value as string}</p> },
                  {
                    name: 'Owner',
                    value: (
                      <>
                        {assetOwner && assetOwner.value ? (
                          <UserRow user={(assetOwner.value as unknown) as UuidableName} />
                        ) : (
                          'N/A'
                        )}
                      </>
                    ),
                  },
                  {
                    name: 'Created',
                    value: <p>{formatDateTime(assetDetails.details.createdAt)}</p>,
                  },
                ]}
              />
            }
            Content={(onError) => <EntityDetailsContent onError={onError} store={assetDetails} />}
            Activities={{
              Content: <EntityDetailsActivities />,
              Footer: <EntityDetailsActivitiesFooter />,
            }}
          />
        );
      }}
    </DetailsLoader>
  );
};

export default AssetsDetails;
