import React from 'react';
import { observer } from 'mobx-react';

import Feed, { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';
import { FeedProps } from 'vatix-ui/lib/components/Feed/types';

import { USER_TAG_BASE } from 'core/constants';
import RootStore from 'stores/Root';
import { SessionStoreWithUser } from 'stores/Session/types';
import API from 'utils/api';
import { useStore } from 'utils/hooks/store';

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
  // TODO: add missing activity types
} as unknown) as FeedProps<RootStore, typeof API>['mapping'];

const EntityDetailsActivities: React.FunctionComponent = (): React.ReactElement => {
  const { session: sessionRaw } = useStore();
  const session = sessionRaw as SessionStoreWithUser;
  // TODO: create a store for site activities
  const activities = {
    isLoading: false,
    hasMore: false,
    api: undefined,
    activities: [],
    loadActivities: () => {},
    reloadActivities: () => {},
    reset: () => {},
  };

  return (
    <Feed
      mapping={activityTypeToComponent}
      // @ts-expect-error
      originalActivities={activities}
      usersSearch={API.searchUsers}
      sessionUser={session.user}
      userTagBaseUrl={USER_TAG_BASE}
    />
  );
};

export default observer(EntityDetailsActivities);
